<template>
  <div id="app">
    <div v-if="step==1">
      <div class="bank_banner2 clear">
        <img src="../assets/images/bank_banner.png" alt=""/>
        <div class='bank_banner_l iconfont' @click="$router.back(-1)">&#xe620;</div>
      </div>
      <div class="bank_title van-ellipsis">修改绑定的银行卡号</div>
      <div class="txb2">
        <van-field
            v-model="info.certNum"
            required
            label="证件号码"
            placeholder="请输入您的身份证证件号码"
        />
      </div>
      <div :class="bindingCardBankName ? 'txb2 mgb_0' : 'txb2'">
        <van-field
            v-model="info.bindingCardNo"
            required
            type="digit"
            label="银行卡号"
            placeholder="请输入更换的新银行卡号"
            @blur="onBlurBindingCardNo()"
        />
      </div>
      <div class="txb2_tips" style="text-align: right;" v-if="bindingCardBankName" :style="{color: validBindingCardNo ? `#A0A0A0` : `#ed3b3b`}">{{ bindingCardBankName }}</div>
      <div class="txb2">
        <van-field
            v-model="info.mobile"
            required
            type="tel"
            label="银行预留手机号码"
            placeholder="请输入银行卡预留手机号码"
        />
      </div>

      <div class="bank_btn">
        <button @click="check" :class="{ forbid: false }">
          提交
        </button>
        <button class="line" @click="back">取消</button>
      </div>

      <div class="bank_tips">
        <div class="bank_tips_t">温馨提示：</div>
        <div class="bank_tips_b">
          目前仅支持广发银行、工商银行、农业银行、交通银行、民生银行、中国邮政储蓄银行、中国银行、建设银行、光大银行、兴业银行、浦发银行、平安银行、中信银行、华夏银行、招商银行、恒丰银行、渤海银行、浙商银行I类户进行在线开户。
        </div>
      </div>
    </div>

    <div v-if="step==2">
      <van-dialog
          v-model="show"
          title="短信验证码"
          confirmButtonText="确定"
          width="64vw"
          show-cancel-button
          @cancel="cancel"
          @confirm="submit"
      >
        <div class="alert_sub_title">请输入尾号 {{ cutMobile }} 收到的验证码</div>
        <div class="alert_gray count_down" v-if="downing">{{ downSecond }} 秒后可再次获取短信</div>
        <div class="alert_gray repeat_sms" v-if="!downing" @click="check">没收到短信？<font>再次发送验证码</font></div>
        <div class="yzm">
          <van-password-input
              :value="smsCode"
              :mask="false"
              :gutter="10"
              :focused="showSmsKeyboard"
              @focus="showSmsKeyboard = true"
          />
        </div>
      </van-dialog>
      <van-number-keyboard
          v-model="smsCode"
          :show="showSmsKeyboard"
          z-index="9999"
          @blur="showSmsKeyboard = false"
      />
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<style scoped>
#app {
  background: #f8f8f8;
  height: 100vh;
  overflow: scroll;
}

::v-deep .van-uploader__upload {
  margin: 0 !important;
  width: 25vw !important;
  height: 16vw !important;
  background: url(../assets/images/photo_upload.png) center center;
  background-size: 100% 100%;
}

::v-deep .van-uploader__preview {
  margin: 0 !important;
}

::v-deep .van-uploader__preview-image {
  width: 25vw !important;
  height: 16vw !important;
}

::v-deep .van-uploader__upload-icon {
  display: none;
}

::v-deep .van-field__label {
  width: 9em !important;
}
</style>

<script>
import Vue from 'vue'
import {post} from "@/utils/http";
import {getRealLength} from "@/utils/util";
import Notify from "vant/es/notify";
import {Dialog, Toast} from 'vant';

export default {
  data() {
    return {
      step: 1,
      show: true,
      smsCode: "",
      showSmsKeyboard: true,
      downSecond: 60,// 倒计时60秒
      downing: false,
      cutMobile: '',

      loading: false,
      checked: false,
      info: {
        certNum: "",
        bindingCardNo: "",
        mobile: "",
        paymentPlatformId: "73d1c2341636435f8e364f9db552a277", // 广发写死
      },
      customerAccount: {},
      validBindingCardNo: false,
      bindingCardBankName: '',

      validSubmit: false,
    };
  },
  watch: {
    smsCode(val) {
      if (val.length == 6) {
        this.showSmsKeyboard = false;
        this.submit()
      }
    }
  },
  mounted() {
    post('api/customer/account/v2/find', {}, true).then(res => {
      this.customerAccount = res.data
    }).catch(() => {
    });


    let that = this
    var downFunc = function () {
      setTimeout(function () {
        if (that.downing) {
          if (that.downSecond > 0) {
            that.downSecond = that.downSecond - 1
          } else {
            that.downing = false
            that.downSecond = 60
          }
        }
        downFunc();
      }, 1000)
    };
    downFunc();
  },
  methods: {
    check() {
      if (!this.customerAccount || !this.customerAccount.id) {
        Notify({type: "danger", message: '您还没有开户'});
        return;
      }

      if (!this.info.certNum) {
        Notify({type: "danger", message: '请输入证件号码'});
        return;
      } else if (!this.info.bindingCardNo) {
        Notify({type: "danger", message: '请输入银行卡号'});
        return;
      } else if (!this.info.mobile) {
        Notify({type: "danger", message: '请输入手机号'});
        return;
      } else if (this.checked == false) {
        // Notify({type: "danger", message: '请阅读和同意相关协议'});
        // return;
      }

      if (!this.checkBindingCardNo()) {
        return;
      }

      if (!this.validBindingCardNo) {
        Notify({type: "danger", message: '请更换符合的银行卡号'});
        return;
      }

      this.loading = true;
      post('/api/guangfa/v2/sendMsgCode', {
        // merchantNum: this.info.merchantNum,
        mobileNo: this.info.mobile,
        msgContentPart1: '更换电子钱包所绑定的银行卡',
        certNo: this.info.certNum
      }).then(r => {
        this.info.msgCodeCheckId = r.data.msgCodeCheckId
        this.step = 2;
        this.show = true;
        this.downing = true
        this.loading = false;
        this.cutMobile = this.info.mobile.length > 4 ? this.info.mobile.substring(this.info.mobile.length - 4, this.info.mobile) : '-'
      }).catch(err => {
        // Notify({type: "danger", message: err.message});
        Dialog.alert({message: err.message}).then(() => {
        });
        this.loading = false;
      })
    },
    submit() {
      this.loading = true;
      post('/api/guangfa/v2/modifyBindingCard', {
        oBankAccountNo: this.info.bindingCardNo,
        oBankMobileNo: this.info.mobile,
        msgCode: this.smsCode,
        certNum: this.info.certNum,
        orMsgFlowNo: this.info.msgCodeCheckId
      }).then(r => {
        Dialog.alert({message: '修改成功'}).then(() => {
          this.back()
        });
        this.loading = false;
      }).catch(err => {
        this.show = false
        let message = '';
        if (err.message === '余额非零，不能更换') {
          message = "您当前绑定的银行卡还有余额,\n为保障您的资金安全,请联系\n客服为您更换绑定哦";
        } else {
          message = err.message;
        }
        Dialog.alert({message: message, confirmButtonText: '好的'}).then(() => {
          this.show = true;
        });

        this.loading = false;
      })
    },
    cancel() {
      this.step = 1;
    },
    back() {
      this.$router.back(-1);
    },

    onBlurBindingCardNo() {
      if (!this.checkBindingCardNo()) {
        return;
      }

      const url = '/api/guangfa/v2/queryBankList';
      this.loading = true;
      post(url, {
        "cardNo": this.info.bindingCardNo
      }).then(res => {
        this.validBindingCardNo = false;
        if (!res || !res.data) {
          return;
        }
        if (!res.data.BankList || res.data.BankList.length <= 0) {
          Toast("请输入正确的银行卡号~");
          return;
        }
        this.bindingCardBankName = res.data.BankList[0].oBankName;
        if (res.data.BankList[0].oBankName === '中国邮政储蓄银行') {
          this.bindingCardBankName = "暂不支持: " + this.bindingCardBankName;
          Toast("暂不支持“中国邮政储蓄银行”哦~请更换新的银行卡");
          return;
        }
        if (res.data.BankList[0].oBankName === '佛山农商银行') {
          this.bindingCardBankName = "暂不支持: " + this.bindingCardBankName;
          Toast("暂不支持“佛山农商银行”哦~请更换新的银行卡");
          return;
        }
        if (res.data.BankList[0].cardType === '贷记卡') {
          this.bindingCardBankName = "暂不支持: 信用卡";
          Toast("暂不支持“信用卡”哦~请更换新的银行卡");
          return;
        }

        this.validBindingCardNo = true;

      }).catch(err => {
        this.validBindingCardNo = false;
      }).finally(() => {
        this.loading = false;
      })
    },

    checkBindingCardNo() {
      if (!this.info.bindingCardNo || this.info.bindingCardNo.length === 0) {
        return false;
      }

      if (!this.info.bindingCardNo || (this.info.bindingCardNo.length !== 16 && this.info.bindingCardNo.length !== 17 && this.info.bindingCardNo.length !== 19)) {
        Toast("请输入正确的银行卡号(" + this.info.bindingCardNo.length + ")");
        return false;
      }
      return true;
    },
  }
};
</script>
